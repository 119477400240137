<script setup lang="ts">
import { ROUTES } from '~/constants/routes';
import { Features } from '~/constants/features';
import { useFeatures } from '~/composables/features/useFeatures';
import { useRealtyRegistration } from '~/composables/realtyRegistration/useRealtyRegistration';

defineProps({
    userId: { type: String, required: true }
});

const { isFeatureEnabled } = useFeatures();
const { loadRealtyRegistrationData } = useRealtyRegistration();

const config = useRuntimeConfig();
const favouritesUrl = config.public.favouritesUrl;
const isHasRealtyRegistration = ref<boolean>(false);

onMounted(async () => {
    await getRealtyRegistration();
});

const getRealtyRegistration = async () => {
    const result = await loadRealtyRegistrationData();
    if (!result.isError && result.data) {
        isHasRealtyRegistration.value = result.data;
    }
};
</script>

<template>
    <div class="menu-header">
        <label class="menu-header-text">{{ $t('routes.menu') }}</label>
    </div>
    <div class="menu-items">
        <NuxtLink 
            :to="ROUTES.user(userId)" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-user-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.profile') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <NuxtLink 
            v-if="config.public.bookingEnabled || isFeatureEnabled(Features.Booking)"
            :to="ROUTES.bookingClient" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-home-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.bookings') }}</span>
        </NuxtLink>
        <NuxtLink 
            v-if="isHasRealtyRegistration"
            :to="ROUTES.realty_registration" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-bill-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.realty_registration') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <NuxtLink 
            :to="ROUTES.settlementClient" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-key-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.settlement') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <NuxtLink 
            :to="ROUTES.partners" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-vip-crown-2-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.partners') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <a :href="favouritesUrl" class="menu-item-link">
            <i class="menu-item-icon ri-heart-3-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.favorites') }}</span>
            <i class="menu-item-icon mobile-visible ri-arrow-right-up-line ri-lg"/>
        </a>
    </div>
</template>

<style scoped lang="scss">
.menu-header {
    display: flex;
    padding: 0 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    /** Заголовки в меню для мобильной версии скрываются */
    @include responsive( 'tablet' ) {
        display: none;
    }

    .menu-header-text {
        color: $color-grey-400;

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.13px;
    }
}

.menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    @include responsive( 'tablet' ) {
        flex-direction: row;
    }

    .menu-item-link {
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;

        @include responsive( 'tablet' ) {
            margin: 12px 8px 0px 8px;
            padding: 0px 0px 20px 0px;
        }

        .menu-item-icon {
            width: 20px;
            height: 20px;
            color: $color-grey-400;

            @include responsive( 'tablet' ) {
                display: none;
            }

            &.mobile-visible{
                @include responsive( 'tablet' ) {
                    display: block;
                }
            }
        }

        .menu-item-text {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
            color: $color-grey-400;
        }

        &.active {
            @include responsive( 'tablet' ) {
                border-bottom: 1px solid $color-red-500;
            }

            .menu-item-icon {
                color: $color-grey-950;
            }
            .menu-item-text {
                color: $color-grey-950;
            }
        }
    }
}
</style>