<script setup lang="ts">
import Notification from '@frontend/ui-kit/src/components/Base/Notification.vue';
import Footer from '~/components/shared/Common/Footer.vue';
import Header from '~/components/shared/Common/Header.vue';
import Navbar from '~/components/shared/Common/Navbar.vue';
import ContinueBookingDialog from '~/components/booking/ContinueBookingDialog.vue';
import { useNotificationStore } from "~/stores/alert/useNotificationStore";
import { useBooking } from '~/composables/booking/useBooking';
import type { BookingStatus } from '~/types/booking/booking';
import { ROUTES } from "~/constants/routes";

const store = useNotificationStore();
const { getActiveBookings, getButtonLink } = useBooking();

const activeBookingId = ref<number | undefined>(undefined);
const isExistAtiveBooking = ref<boolean>(false);

defineProps({
    isError: { type:Boolean, default: false }
});

onMounted(async () => {
    // Проверяем активные бронирования, если больше 2-х, то редиректим на список.
    const result = await getActiveBookings();
    if(!result.isError) {
        const activeBookings = result.data ?? [];
        if(activeBookings.length === 1) {
            activeBookingId.value = activeBookings[0];
            isExistAtiveBooking.value = true;
        }
    }
});

const onContinueBooking = async (id: number, status: BookingStatus) : Promise<void> => {
    window.location.href = `${ROUTES.booking_id(id)}?step=${getButtonLink(status)}`;
}
</script>

<template>
    <v-app class="app">
        <v-layout class="layout">
            <Navbar />
            <div class="main">
                <div v-if="!isError" class="main-container">
                    <Header />
                    <slot />
                </div>
                <div v-else class="main-container">
                    <slot />
                </div>
                <Footer />
            </div>
            <Notification :messages="store.messages"/>
            <ContinueBookingDialog 
                v-if="isExistAtiveBooking" 
                v-model="isExistAtiveBooking" 
                :bookingId="activeBookingId"
                @continue="onContinueBooking"
            />
        </v-layout>
    </v-app>
</template>

<style scoped lang="scss">
.app {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    background-color: $color-grey-100;

    :deep(.v-application__wrap) {
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
}

.layout {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    
    @include responsive( 'tablet' ) { 
        flex-direction: column;
    }
}

.main {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-self: stretch;
    gap: 32px;
    flex: 1 0 0;
    max-width: 1000px;
    align-items: flex-start;
    justify-content: flex-start;

    @include responsive( 'tablet' ) { 
        padding: 0;
        justify-content: flex-start;
        align-items: center;
    }
    
    .main-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        width: 100%;
        padding: 40px 80px;
        border-radius: 32px;
        background: $main-card-color;
        box-shadow: $box-shadow-02;

        @include responsive( 'desktop-small' ) {
            padding: 40px;
        }
        
        @include responsive( 'laptop' ) {
            padding: 24px 32px;
        }

        @include responsive( 'tablet' ) {
            max-width: 640px;
            padding: 32px 24px;
        }
    }
}
</style>