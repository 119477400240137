import type { Result } from "@frontend/ui-kit/src/types/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';

/** Методы функциональностей. */
export const useRealtyRegistration = () => {
    const { get } = useApi();
    const { trySilentAction } = useSafe();
    const config = useRuntimeConfig();
    
    /**
     * Признак, что у пользователя присутствует онлайн регистрация
     */
    const loadRealtyRegistrationData = async () : Promise<Result<boolean>> => {
        return await trySilentAction(async () => {
            const url = `${config.public.realtyRegistrationApiBaseUrl}/active`;
            return await get<boolean>(url);
        });
    }

    return {
        loadRealtyRegistrationData,
    }
}