import { jwtDecode } from 'jwt-decode';

/** Методы функциональностей. */
export const useFeatures = () => {
    /**
     * Признак, что функциональность работает для конкретного пользователя.
     * @param featureName Наименование функциональности.
     */
    const isFeatureEnabled = (featureName: string) : boolean => {
        const tokenCookie = useCookie("token");
        if(!tokenCookie.value) {
            return false;
        };

        const json = jwtDecode(tokenCookie.value!);
        // @ts-expect-error JwtPAyload не индексатор официально
        const isEnabled = json[featureName];
        return isEnabled as boolean;
    }

    return {
        isFeatureEnabled,
    }
}